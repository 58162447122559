import React, { useState, useEffect } from 'react'
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import moment from 'moment';
import HolidaysCalender from './calender/HolidaysCalender';
import { useNavigate } from 'react-router-dom';
import JajotApi from '../api/JajotApi'; 
import Shimmer from './common/Shimmer';
import ProgressBar from 'react-bootstrap/ProgressBar';
import jwtDecode from 'jwt-decode';
import toast, { Toaster } from 'react-hot-toast';

const Home = () => {
  const [contactInfo, setContactInfo] = useState();
  const [targets, setTargets] = useState();
  const [isSpinner, setISspinner] = useState(true);
  const [remainingPercentageOfTarget, setRemainingPercentageOfTarget] = useState();
  const [employeeDetails, setEmployeeDetails] = useState();
  const [stateChanger, setStateChanger] = useState(false);
  const [editEmployee, setEditEmployee] = useState(false);
  const navigate = useNavigate();
  const [employeUpdate, setEmployeeUpdate] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployeeUpdate(prevState => ({ ...prevState, [name]: value }));
  }

  const editEmployeeRecord = async () => {
    if (!(employeUpdate.firstName)) {
      toast.error("First name sould not be empty")
      return
    }
    if (!(employeUpdate.lastName)) {
      toast.error("Last name sould not be empty")
      return
    }
    if (!(employeUpdate.phoneNumber)) {
      toast.error("Phone number sould not be empty")
      return
    }
    if (employeUpdate.phoneNumber.length < 10 || employeUpdate.phoneNumber.length > 10) {
      toast.error("Phone number should be in 10 digits ")
      return
    }
    if (!(employeUpdate.address)) {
      toast.error("Address sould not be empty")
      return
    }
    setEditEmployee(false);
    setISspinner(false);
    let result = await JajotApi.updateEmployeeRecords(employeUpdate);
    if (result?.message === 'Your information updated successfully.') {
      toast.success("Your information updated successfully")
      setISspinner(true);
      setStateChanger(!stateChanger);
    }
  }

  const editEmployeeInfo = (event) => {
    const employeeObj = {
      firstName: event.FirstName,
      lastName: event.LastName,
      email: event.Email,
      phoneNumber: event.Phone,
      dateOfBirth: event.Birthdate,
      dateOfMarriage: event.Date_of_Marriage__c,
      address: event.Temporary_Address__c
    }
    setEmployeeUpdate(employeeObj);
  }

  const getContactRecord = async () => {
    let user = jwtDecode(localStorage.getItem('token'));
    let result = await JajotApi.getContactRecordByEmail(user?.contactDetails?.Email);
    console.log(result);
    if (result?.success) {
      setEmployeeDetails(result?.records);
    }
  }
  const formatNumberWithDecimals = (number) => {
    const formattedNumber = number?.toLocaleString('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return `${formattedNumber}`;
  }
  useEffect(() => {
    try {
      if (!localStorage.getItem("token")) navigate("/");
      getContactRecord();
      (async () => {
        let user = jwtDecode(localStorage.getItem('token'));        
        setContactInfo(user)
        let result = await JajotApi.getRemainingAndTarget(user.contactDetails.Id);
        if (result?.success) {
          let calculation = (result.records[0])
          setRemainingPercentageOfTarget((calculation.Remaining_Target__c / calculation.Given_Target__c) * 100)
          setTargets(...result.records);
        }
      })();
    } catch (error) {
      throw error;
    }
  }, [stateChanger, isSpinner]);

  return employeeDetails !== undefined ? (
    <React.Fragment>
      <Toaster />

      {/* Home Section Employee Details */}
      <Container className='pb-2'>
        <Row className='mt-3 mx-3'>
          <Col lg={8} md={12} sm={12} xs={12}>
            {
              isSpinner === true ?

                !editEmployee ?
                  <Col lg={12} sm={12} xs={12} className='mt-1'>
                    <Card className="mt-1" style={{ borderRadius: "5px" }}>
                      <Card.Header>
                        <Row>
                          <Col lg={6} sm={8} xs={8} >
                            <span className="fw-semibold heading" style={{ color: "var(--hightlight-color)" }}>Employee Details</span>
                          </Col>
                          <Col lg={6} sm={4} xs={4} className='text-end'>
                            <i className="fa-regular fa-pen-to-square p-1" title='Edit Profile' style={{ cursor: "pointer" }} onClick={() => { setEditEmployee(true); editEmployeeInfo(employeeDetails) }}></i>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Card.Body>
                        <Row className='mx-1'>
                          <Col lg={6} sm={12} xs={12} style={{ padding: ".33rem" }}><strong>First Name:</strong><span className='mx-3'>{employeeDetails?.FirstName}</span></Col>
                          <Col lg={6} sm={12} xs={12} style={{ padding: ".33rem" }}><strong>Last Name:</strong><span className='mx-3'>{employeeDetails?.LastName}</span></Col>
                          <Col lg={6} sm={12} xs={12} style={{ padding: ".33rem" }}><strong>Designation:</strong><span className='mx-3'>{employeeDetails?.Designation__c}</span></Col>
                          <Col lg={6} sm={12} xs={12} style={{ padding: ".33rem" }}><strong>Gender:</strong><span className='mx-3'>{employeeDetails?.Gender__c}</span></Col>
                          <Col lg={6} sm={12} xs={12} style={{ padding: ".33rem" }}><strong>Email:</strong><span className='mx-3'>{employeeDetails?.Email}</span></Col>
                          <Col lg={6} sm={12} xs={12} style={{ padding: ".33rem" }}><strong>Mobile:</strong><span className='mx-3'>{employeeDetails?.Phone}</span></Col>
                          <Col lg={12} sm={12} xs={12} style={{ padding: ".33rem" }}><strong>Address:</strong><span className='mx-3'>{employeeDetails?.Temporary_Address__c}</span></Col>
                          <br /><br /><hr /><br />
                          <Col lg={6} sm={12} xs={12} style={{ padding: ".33rem" }}><strong>Experience:</strong><span className='mx-3'>{employeeDetails?.Current_Experience__c == null ? '0' : employeeDetails?.Current_Experience__c}</span></Col>
                          <Col lg={6} sm={12} xs={12} style={{ padding: ".33rem" }}><strong>Date Of Birth:</strong><span className='mx-3'>{employeeDetails?.Birthdate == null ? '' : moment(employeeDetails?.Birthdate).format('DD-MM-YYYY')}</span></Col>
                          <Col lg={6} sm={12} xs={12} style={{ padding: ".33rem" }}><strong>Date of Marriage:</strong><span className='mx-3'>{employeeDetails?.Date_of_Marriage__c == null ? '' : moment(employeeDetails?.Date_of_Marriage__c).format('DD-MM-YYYY')}</span></Col>
                          <Col lg={6} sm={12} xs={12} style={{ padding: ".33rem" }}><strong>Date of Joining:</strong><span className='mx-3'> {employeeDetails?.Date_of_Joining__c == null ? '' : moment(employeeDetails?.Date_of_Joining__c).format('DD-MM-YYYY')}</span></Col>
                          <Col lg={6} sm={12} xs={12} style={{ padding: ".33rem" }}><strong>Reporting Person:</strong><span className='mx-3'> {employeeDetails?.Reporting_Person__r?.Name}</span></Col>
                          <br /><br /><hr />  <br />
                          <Col lg={6} sm={12} xs={12} style={{ padding: ".33rem" }}><strong>Bank Name:</strong><span className='mx-3'>{employeeDetails?.Bank_Name__c}</span></Col>
                          <Col lg={6} sm={12} xs={12} style={{ padding: ".33rem" }}><strong>Bank Account Number:</strong><span className='mx-3'>{employeeDetails?.Bank_Account_Number__c}</span></Col>
                          {/* <Col lg={6} sm={12} xs={12} style={{ padding: ".33rem" }}><strong>PF Number:</strong><span className='mx-3'>{employeeDetails?.PF_Number__c}</span></Col> */}
                          {/* <Col lg={6} sm={12} xs={12} style={{ padding: ".33rem" }}><strong>ESIC Number:</strong><span className='mx-3'>{employeeDetails?.ESIC_Number__c}</span></Col> */}
                          <Col lg={6} sm={12} xs={12} style={{ padding: ".33rem" }}><strong>PAN Card Number:</strong><span className='mx-3'>{employeeDetails?.PAN_Card_Number__c}</span></Col>
                          <Col lg={6} sm={12} xs={12} style={{ padding: ".33rem" }}><strong>Aadhar Card Number:</strong><span className='mx-3'>{employeeDetails?.Aadhaar_Card_Number__c	}</span></Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>

                  : <Card className='mt-1' style={{ borderRadius: "5px", }}>
                    <Card.Header>
                      <Row>
                        <Col lg={12} sm={12} xs={12} >
                          <span className="fw-semibold heading">Employee Details</span>
                        </Col>
                      </Row>
                    </Card.Header>

                    <Card.Body>
                      <Row className='mx-1 mb-4'>
                        <Col lg={6} sm={12} xs={12}>
                          <Form.Group className='mt-1'>
                            <Form.Label className="form-view-label" htmlFor="formBasicFirstName">First Name</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              name="firstName"
                              defaultValue={employeUpdate.firstName || ''}
                              onChange={handleChange}
                              maxLength={40}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={6} sm={12} xs={12} >
                          <Form.Group className='mt-1'>
                            <Form.Label className="form-view-label" htmlFor="formBasicFirstName">Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              required
                              name="lastName"
                              defaultValue={employeUpdate.lastName || ''}
                              onChange={handleChange}
                              maxLength={40}
                            />
                          </Form.Group>
                        </Col>

                        <Col lg={6} sm={12} xs={12}>
                          <Form.Group className='mt-3'>
                            <Form.Label className="form-view-label" htmlFor="formBasicFirstName">Phone</Form.Label>
                            <Form.Control
                              required
                              type="phone"
                              name="phoneNumber"
                              defaultValue={employeUpdate.phoneNumber || ''}
                              onChange={handleChange}
                              maxLength={20}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={6} sm={12} xs={12}>
                          <Form.Group className='mt-3'>
                            <Form.Label className="form-view-label" htmlFor="formBasicFirstName">Birthdate</Form.Label>
                            <Form.Control
                              required
                              type="date"
                              name="dateOfBirth"
                              value={employeUpdate.dateOfBirth || ''}
                              max={moment(new Date()).format("YYYY-MM-DD")}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={6} sm={12} xs={12}>
                          <Form.Group className='mt-3'>
                            <Form.Label className="form-view-label" htmlFor="formBasicFirstName">Date of Marriage</Form.Label>
                            <Form.Control
                              type="date"
                              name="dateOfMarriage"
                              defaultValue={employeUpdate.dateOfMarriage || ''}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={6} sm={12} xs={12}>
                          <Form.Group className='mt-3'>
                            <Form.Label className="form-view-label" htmlFor="formBasicFirstName">Address</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              name="address"
                              defaultValue={employeUpdate.address || ''}
                              onChange={handleChange}
                              maxLength={255}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>

                    <Card.Footer style={{ backgroundColor: "white" }} >
                      <Row>
                        <Col lg={12} sm={12} xs={12} className="text-end">
                          <button className='btn btn-primary mx-2' onClick={() => setEditEmployee(false)}>Close</button>
                          <button className='btn btn-primary' onClick={editEmployeeRecord} >Save</button>
                        </Col>
                      </Row>
                    </Card.Footer>
                  </Card>

                : isSpinner == false && <Shimmer />
            }
          </Col>


          {/* Calendar Design */}
          <Col lg={4} md={12} sm={12} xs={12}>
            <Row>
              <Container className='mt-1'>
                <Col lg={12} sm={12} xs={12} className="text-center" style={{ borderRadius: "4px", backgroundColor: 'var(--primary-color)', color: "var(--highlight-color)" }}>
                  <Card className='p-0'>
                    <Card.Header className='calendar'>
                      <span className="fw-semibold text-light heading " >Calendar</span>
                    </Card.Header>
                    <Card.Body className='p-0'>
                      <HolidaysCalender />
                    </Card.Body>
                  </Card>
                </Col>
              </Container>
            </Row>

            <Row className='mt-3 pb-3 '>
              <Col className='rounded' >
                <Card>
                  <Card.Header className='calendar'>
                    <span className='heading' >Target <i className='text-white' style={{ fontSize: "12px" }}>(Current Month)</i> </span>
                  </Card.Header>
                  {
                    (targets !== undefined) ?
                      remainingPercentageOfTarget <= 0 ?
                        <Card.Body className='p-4'>
                          <Container>
                            <Row>
                              <Col className='d-flex align-items-center  justify-content-around'>
                                <i className="fa-solid fa-trophy" style={{ fontSize: "70px", color: "#FFD700" }}></i>
                              </Col>
                            </Row>
                            <Row>
                              <Col className='d-flex align-items-center  justify-content-around'>
                                <div>
                                  <h5 className='mt-1'>Congratulations</h5>
                                  <span className='mt-2 fw-bold text-center heading'>Target Achieved</span>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </Card.Body>
                        :
                        <Card.Body className='p-4 mb-3'>
                          <Container className='d-flex align-items-center justify-content-around'>
                            <Row className='trophy w-100'>
                              <Col md={12} sm={12} lg={6} className='d-flex justify-content-center '>
                                <i className="fa-solid fa-trophy" style={{ fontSize: "70px", color: "#FFD700" }}></i>
                              </Col>
                              <Col lg={6} sm={12} md={12} className='pt-3'>
                                <ProgressBar>
                                  <ProgressBar variant='warning' animated now={remainingPercentageOfTarget} key={2} />
                                  <ProgressBar style={{ backgroundColor: "var(--primary-color)" }} animated now={100 - remainingPercentageOfTarget} key={1} />
                                </ProgressBar>
                                <div className='mt-1 text-center fw-bold'>Target Achieved</div>
                              </Col>
                            </Row>
                          </Container>
                          <Row className='bottom mt-4 gap-0 d-flex justify-content-between aling-items-center' style={{ fontSize: "14px " }}>
                            <Col lg={6} md={12} sm={12} className='d-flex rounded-1 justify-content-between border border-start-0 border-warning align-items-center ps-0 pe-1'>
                              <span className='badge rounded-1 bg-warning border border-warning h-100 d-flex align-items-center justify-content-center' style={{ width: "45%" }}>Remaining</span> <span className='fw-bold'>&#8377; {formatNumberWithDecimals(targets?.Remaining_Target__c)}</span>
                            </Col>
                            <Col lg={6} md={12} sm={12} className='rounded-1 d-flex justify-content-between border border-success border-start-0 ps-0 pe-1 align-items-center'>
                              <span className='badge rounded-1 h-100 d-flex align-items-center justify-content-center' style={{ backgroundColor: "var(--primary-color)", width: "45%" }}>Achieved</span> <span className='fw-bold'>&#8377; {formatNumberWithDecimals(targets?.Achieved_Target__c)}</span>
                            </Col>
                          </Row>
                        </Card.Body>
                      :
                      <Card.Body className='p-4'>
                        <Container>
                          <span className='fw-bold' style={{ fontSize: "20px" }}>
                            No Target for this month
                          </span>
                        </Container>
                      </Card.Body>
                  }
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  ) : <Shimmer />
}

export default Home;